import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import AcdCycleIcon from '../../../assets/icons/acd-cycle.svg?react';
import CustomerIcon from '../../../assets/icons/customer.svg?react';
import RocIcon from '../../../assets/icons/roc.svg?react';
import { MainNavMenuButton } from '../../5-elements';
export const MainNavMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const mainNavMenuConfig = [
    { label: t('mainNavMenu.customers'), icon: <CustomerIcon />, to: '/portal/customers' },
    { label: t('mainNavMenu.devices'), icon: <RocIcon />, to: '/portal/devices' },
    { label: t('mainNavMenu.operations'), icon: <AcdCycleIcon />, to: '/portal/operations' }
  ];

  return (
    <nav className="main-nav-menu" data-testid="portal-main-nav-menu">
      {mainNavMenuConfig.map((menuItem) => (
        <MainNavMenuButton key={menuItem.label} {...menuItem} />
      ))}
    </nav>
  );
};
