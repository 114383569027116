import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { GetDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { TIMEZONE_COOKIE_NAME } from '../../../../constants';
import { getDeviceMeasurementValue, mapDeviceStateToStatusIcon } from '../../../../utilities';
import { InformationBlock, RSCard, RSCardDefaultTitle, RSLinearProgress } from '../../../5-elements';
import {
  DeviceStatus,
  calculateUsagePercentage,
  contextualizeConnectivityStatusDisplayValue,
  contextualizeSafetyTriggeredDisplayValue,
  generateDisplayTimestamp,
  mapConnectivityToStatusIcon,
  mapMeasurementStatusToStatusIcon,
  mapSafetyTriggeredToStatusIcon,
  mapUsageStatusToProgressBarColor
} from '../device-status';

interface DeviceHealthCardsProps {
  deviceLastState?: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceLastState'];
  deviceMeasurementValues?: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceMeasurementValues'];
}

export const DeviceHealthCards = ({
  deviceLastState,
  deviceMeasurementValues = []
}: DeviceHealthCardsProps): JSX.Element => {
  const [cookies] = useCookies([TIMEZONE_COOKIE_NAME]);
  const { t } = useTranslation();

  const safetyTriggeredMeasurement = getDeviceMeasurementValue('safety_triggered', deviceMeasurementValues);
  const connectivityMeasurement = getDeviceMeasurementValue('is_online', deviceMeasurementValues);
  const memoryUsageMeasurement = getDeviceMeasurementValue('memory_usage', deviceMeasurementValues);
  const memoryTotalMeasurement = getDeviceMeasurementValue('memory_total', deviceMeasurementValues);
  const storageUsageMeasurement = getDeviceMeasurementValue('storage_usage', deviceMeasurementValues);
  const storageTotalMeasurement = getDeviceMeasurementValue('storage_total', deviceMeasurementValues);
  const queueLengthMeasurement = getDeviceMeasurementValue('remote_queue_length', deviceMeasurementValues);

  const safetyTriggeredValue = contextualizeSafetyTriggeredDisplayValue(safetyTriggeredMeasurement?.value);
  const connectivityStateValue = contextualizeConnectivityStatusDisplayValue(connectivityMeasurement?.value);
  const memoryUsageValue = calculateUsagePercentage(memoryUsageMeasurement?.value, memoryTotalMeasurement?.value);
  const storageUsageValue = calculateUsagePercentage(storageUsageMeasurement?.value, storageTotalMeasurement?.value);

  return (
    <div className="device-health-cards">
      <RSCard
        dataTestId="device-health-card-device-state"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.deviceState.title')} />}
      >
        <InformationBlock
          value={
            <DeviceStatus
              statusIconVariant={mapDeviceStateToStatusIcon(deviceLastState?.deviceStatus)}
              statusValue={deviceLastState?.deviceStatus}
              stateName={deviceLastState?.stateName}
            />
          }
          subValue={generateDisplayTimestamp(deviceLastState?.updatedAt, cookies[TIMEZONE_COOKIE_NAME])}
        />
      </RSCard>
      <RSCard
        dataTestId="device-health-card-safety-triggered"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.safetyTriggered.title')} />}
      >
        <InformationBlock
          value={
            <DeviceStatus
              statusIconVariant={mapSafetyTriggeredToStatusIcon(safetyTriggeredMeasurement?.value)}
              statusValue={safetyTriggeredValue}
            />
          }
          subValue={generateDisplayTimestamp(safetyTriggeredMeasurement?.updatedAt, cookies[TIMEZONE_COOKIE_NAME])}
        />
      </RSCard>
      <RSCard
        dataTestId="device-health-card-connectivity-state"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.connectivityState.title')} />}
      >
        <InformationBlock
          value={
            <DeviceStatus
              statusIconVariant={mapConnectivityToStatusIcon(connectivityMeasurement?.value)}
              statusValue={connectivityStateValue}
            />
          }
          subValue={generateDisplayTimestamp(connectivityMeasurement?.updatedAt, cookies[TIMEZONE_COOKIE_NAME])}
        />
      </RSCard>
      <RSCard
        dataTestId="device-health-card-system-health"
        extraClassNames={['device-health-cards__card']}
        title={<RSCardDefaultTitle title={t('deviceDetailsPage.deviceHealth.measurements.title')} />}
      >
        <div className="device-health-cards__measurements">
          <div className="device-health-cards__measurement">
            <DeviceStatus
              statusIconVariant={mapMeasurementStatusToStatusIcon(memoryUsageMeasurement?.status)}
              statusValue={t('deviceDetailsPage.deviceHealth.measurements.memory')}
              sizeVariant="small"
            />
            <RSLinearProgress
              data-testid="device-health-memory-usage-progress-bar"
              value={memoryUsageValue}
              labelTextSize="large"
              widthVariant="long"
              color={mapUsageStatusToProgressBarColor(memoryUsageMeasurement?.status)}
            />
          </div>
          <div className="device-health-cards__measurement">
            <DeviceStatus
              statusIconVariant={mapMeasurementStatusToStatusIcon(storageUsageMeasurement?.status)}
              statusValue={t('deviceDetailsPage.deviceHealth.measurements.storage')}
              sizeVariant="small"
            />
            <RSLinearProgress
              data-testid="device-health-storage-usage-progress-bar"
              value={storageUsageValue}
              labelTextSize="large"
              widthVariant="long"
              color={mapUsageStatusToProgressBarColor(storageUsageMeasurement?.status)}
            />
          </div>
          <div className="device-health-cards__measurement">
            <DeviceStatus
              statusIconVariant={mapMeasurementStatusToStatusIcon(queueLengthMeasurement?.status)}
              statusValue={t('deviceDetailsPage.deviceHealth.measurements.queueLength')}
              sizeVariant="small"
            />
            <span>{queueLengthMeasurement?.value}</span>
          </div>
        </div>
      </RSCard>
    </div>
  );
};
