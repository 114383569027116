import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ACDCycleOperationResultSuccessRates, PerformanceMetricPresentationLayout } from '../../../../types';
import { PerformanceMetricBlock } from '../../performance-metric-block/performance-metric-block';

interface PerformanceMetricsRenderProps extends ACDCycleOperationResultSuccessRates {
  layout: PerformanceMetricPresentationLayout;
  loadingTotalAcdCyclesOperationResult: boolean;
  errorTotalAcdCyclesOperationResult: boolean;
  isRawPerformance?: boolean;
}

export const PerformanceMetricsRender = ({
  layout,
  acdCycleOperationsSuccessRate,
  acdCycleOperationsSuccessRateChange,
  acdCycleOperationsCount,
  acdCycleOperationsCountChange,
  loadingTotalAcdCyclesOperationResult,
  errorTotalAcdCyclesOperationResult,
  isRawPerformance
}: PerformanceMetricsRenderProps) => {
  const { t } = useTranslation();
  const blockClassNames = classNames('performance-metrics-render__presentation', {
    'performance-metrics-render__presentation--align-right': layout === 'align-right',
    'performance-metrics-render__presentation--full-width': layout === 'full-width'
  });

  return (
    <div className="performance-metrics-render" data-testid="performance-metrics-render">
      <div className={blockClassNames} data-testid="performance-metrics-presentation">
        <div
          className="performance-metrics-render__presentation-block"
          data-testid="performance-metrics-presentation-block"
        >
          <PerformanceMetricBlock
            metric={{ value: acdCycleOperationsSuccessRate, displayUnit: t('units.percentage') }}
            metricChange={
              acdCycleOperationsSuccessRateChange === undefined
                ? undefined
                : {
                    value: acdCycleOperationsSuccessRateChange,
                    displayUnit: t('units.percentage')
                  }
            }
            loading={loadingTotalAcdCyclesOperationResult}
            hasError={errorTotalAcdCyclesOperationResult}
            label={
              isRawPerformance
                ? t('performanceMetricsBlocks.labels.rawPerformance')
                : t('performanceMetricsBlocks.labels.operationalPerformance')
            }
            data-testid="performance-metrics-render-operation-performance"
          />
        </div>
        <div
          className="performance-metrics-render__presentation-block"
          data-testid="performance-metrics-presentation-block"
        >
          <PerformanceMetricBlock
            metric={{ value: acdCycleOperationsCount, decimalPlaces: 0 }}
            metricChange={
              acdCycleOperationsCountChange === undefined
                ? undefined
                : { value: acdCycleOperationsCountChange, displayUnit: t('units.percentage') }
            }
            loading={loadingTotalAcdCyclesOperationResult}
            hasError={errorTotalAcdCyclesOperationResult}
            label={t('performanceMetricsBlocks.labels.acdCycles')}
            data-testid="performance-metrics-render-acd-cycles-performance"
          />
        </div>
      </div>
    </div>
  );
};
