import {
  CustomerOrderBy,
  DeviceOperationBoolExp,
  DeviceOperationOrderBy,
  DeviceOrderBy,
  OrderBy
} from '../__generated__/graphql';
import { EnvironmentSetting } from '../types/environment-setting';

export const PORTAL_CLIENT_ID: EnvironmentSetting = {
  DEV: 'MD0sqf9rgSp1OON9WpMXHapKVogWlHjR',
  INT: 'MD0sqf9rgSp1OON9WpMXHapKVogWlHjR',
  ACC: 'hoNfFcAIBvKJcK3InROpWqrPlj7oby5T',
  PROD: 'b92Ikrxg3qdtBifLJUAHqoDwIqBHurc5'
};

export const PORTAL_AUDIENCE: EnvironmentSetting = {
  DEV: 'https://cloud-portal.rocsys.int',
  INT: 'https://cloud-portal.rocsys.int',
  ACC: 'https://cloud-portal.rocsys.acc',
  PROD: 'https://cloud-portal.rocsys.prod'
};

export const GRAPHQL_ENDPOINT_URL: EnvironmentSetting = {
  DEV: 'http://localhost:8080',
  INT: '',
  ACC: '',
  PROD: ''
};

// Cookies
export const TIMEZONE_COOKIE_NAME = 'rocsys-portal-timezone';

// Data grids
export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_GET_CUSTOMERS_SORT_BY: CustomerOrderBy[] = [{ company: { name: OrderBy.Asc } }];
export const DEFAULT_GET_DEVICES_SORT_BY: DeviceOrderBy[] = [{ serialNumber: OrderBy.Asc }];
export const DEFAULT_GET_OPERATIONS_SORT_BY: DeviceOperationOrderBy[] = [{ startAt: OrderBy.Desc }];
export const DEFAULT_GET_OPERATIONS_FILTER: DeviceOperationBoolExp = { operationName: { _eq: 'ACDCycle' } };

// Performance metrics
export const DATE_FORMAT_TEMPLATE_PERFORMANCE_STATS = 'yyyy-MM-dd HH:mm:ss Z';
