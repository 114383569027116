import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import { get, omit } from 'lodash';
import queryString from 'query-string';
import { JSX } from 'react';
import { useSearchParams } from 'react-router-dom';

import IconArrowUp from '../../../../assets/icons/arrow-up.svg?react';
import IconArrowsUpAndDown from '../../../../assets/icons/arrows-up-and-down.svg?react';

interface DataGridHeaderCellProps {
  title: string;
  field: string;
  sortable?: boolean;
}

/**
 * This component renders an DataGridHeaderCell component.
 * @param {string} [title] The text to display in the header cell.
 * @returns {JSX.Element} An DataGridHeaderCell component.
 */

export const DataGridHeaderCell = ({ title, field, sortable }: DataGridHeaderCellProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  // The only source of truth for the sorting state comes from the URL.
  // Therefore we use the URL search param to determine the state.
  const originalSearchParamsObject = queryString.parse(searchParams.toString(), {
    arrayFormat: 'comma'
  });

  const handleClickAscButton = (): void => {
    const newSearchParams = { ...originalSearchParamsObject, sort: `-${field}`, page: '1' };
    setSearchParams(new URLSearchParams(queryString.stringify(newSearchParams, { arrayFormat: 'comma' })));
  };
  const handleClickDescButton = (): void => {
    const newSearchParams = { ...omit(originalSearchParamsObject, 'sort'), page: '1' };
    setSearchParams(new URLSearchParams(queryString.stringify(newSearchParams, { arrayFormat: 'comma' })));
  };
  const handleClickUnsortedButton = (): void => {
    const newSearchParams = { ...originalSearchParamsObject, sort: field, page: '1' };
    setSearchParams(new URLSearchParams(queryString.stringify(newSearchParams, { arrayFormat: 'comma' })));
  };

  const determineSortMethod = () => {
    if (sortSearchParam === field) {
      return handleClickAscButton;
    }
    if (sortSearchParam === `-${field}`) {
      return handleClickDescButton;
    }
    return handleClickUnsortedButton;
  };

  const sortSearchParam = get(originalSearchParamsObject, 'sort');
  const fieldSorted = sortSearchParam === field || sortSearchParam === `-${field}`;

  return (
    <>
      {sortable ? (
        <ButtonBase
          className="data-grid-header-cell"
          disableRipple
          onClick={determineSortMethod()}
          data-testid="data-grid-header-cell-sortable"
        >
          <p className="data-grid-header-cell__text">{title}</p>
          <IconButton
            component="div"
            className="data-grid-header-cell__icon-button"
            data-testid="data-grid-sorting-icon"
          >
            <div className="data-grid-header-cell__icon-container">
              {fieldSorted ? (
                <IconArrowUp
                  className={classNames('data-grid-header-cell__icon data-grid-header-cell__icon--active', {
                    'data-grid-header-cell__icon--down': sortSearchParam === `-${field}`
                  })}
                  data-testid="data-grid-icon-sorted"
                />
              ) : (
                <IconArrowsUpAndDown className="data-grid-header-cell__icon" data-testid="data-grid-icon-not-sorted" />
              )}
            </div>
          </IconButton>
        </ButtonBase>
      ) : (
        <div className="data-grid-header-cell" data-testid="data-grid-header-cell">
          <p className="data-grid-header-cell__text">{title}</p>
        </div>
      )}
    </>
  );
};
