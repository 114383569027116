import { QueryHookOptions, useQuery } from '@apollo/client';

import { PerformanceMetricsRender } from './performance-metrics-render';
import { DeviceOperationBoolExp, GetDeviceOperationsPerformanceQuery } from '../../../__generated__/graphql';
import { QUERY_GET_DEVICE_OPERATIONS_PERFORMANCE } from '../../../services/queries';
import { PerformanceMetricPresentationLayout } from '../../../types';
import {
  calculateACDCycleOperationsResultSuccessRates,
  generatePerformanceMetricsFilters,
  getAcdCycleOperationsCounts
} from '../../../utilities';

interface PerformanceMetricsProps {
  layout: PerformanceMetricPresentationLayout;
  filters: DeviceOperationBoolExp;
  queryHookOptions?: QueryHookOptions<GetDeviceOperationsPerformanceQuery>;
}

export const PerformanceMetrics = ({ layout, filters, queryHookOptions }: PerformanceMetricsProps) => {
  const { loading, data, error } = useQuery(QUERY_GET_DEVICE_OPERATIONS_PERFORMANCE, {
    ...queryHookOptions,
    variables: { ...generatePerformanceMetricsFilters(filters) }
  });

  const counts = getAcdCycleOperationsCounts(data);

  const values = calculateACDCycleOperationsResultSuccessRates(
    counts.acdCycleOperationsSuccess,
    counts.acdCycleOperationsFailed,
    counts.acdCycleOperationsPrevSuccess,
    counts.acdCycleOperationsPrevFailed
  );

  return (
    <PerformanceMetricsRender
      layout={layout}
      acdCycleOperationsSuccessRate={values.acdCycleOperationsSuccessRate}
      acdCycleOperationsSuccessRateChange={values.acdCycleOperationsSuccessRateChange}
      acdCycleOperationsCount={values.acdCycleOperationsCount}
      acdCycleOperationsCountChange={values.acdCycleOperationsCountChange}
      loadingTotalAcdCyclesOperationResult={loading}
      errorTotalAcdCyclesOperationResult={!!error}
    />
  );
};
