import { useTranslation } from 'react-i18next';

import OfflineIcon from '../../../assets/icons/offline.svg?react';

interface DeviceOfflineWarningBarProps {
  timestamp: string;
}

export const DeviceOfflineWarningBar = ({ timestamp }: DeviceOfflineWarningBarProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="device-offline-warning-bar" data-testid="device-offline-warning-bar">
      <div className="device-offline-warning-bar__icon-container">
        <OfflineIcon className="device-offline-warning-bar__icon" />
      </div>
      <p className="device-offline-warning-bar__text">{t('deviceDetailsPage.offlineWarningBar', { timestamp })}</p>
    </div>
  );
};
