import classNames from 'classnames';
import { JSX } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface MainNavMenuProps extends NavLinkProps {
  label: string;
  icon: JSX.Element;
}

export const MainNavMenuButton = ({ label, icon, ...props }: MainNavMenuProps): JSX.Element => {
  const menuButtonClassNames = (isActive: boolean): string => {
    return classNames({
      'main-nav-menu-button': true,
      'main-nav-menu-button--active': isActive === true
    });
  };

  return (
    <NavLink data-testid="main-nav-menu-button" {...props} className={({ isActive }) => menuButtonClassNames(isActive)}>
      <div className="main-nav-menu-button__icon-container">{icon}</div>
      <p className="main-nav-menu-button__label">{label}</p>
    </NavLink>
  );
};
