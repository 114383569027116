import { useQuery } from '@apollo/client';
import { find, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { JSX, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { GetOperationDetailsQuery, GetOperationImageUrlsQuery, ImageType } from '../../../../__generated__/graphql';
import LogIcon from '../../../../assets/icons/log.svg?react';
import MetadataIcon from '../../../../assets/icons/metadata.svg?react';
import { TIMEZONE_COOKIE_NAME } from '../../../../constants';
import { QUERY_GET_OPERATION_IMAGE_URLS } from '../../../../services/queries';
import { Flatten, ObjectUploadStatus, SnackbarMessageType } from '../../../../types';
import { calculateDuration, constructSnackbarMessage, generateOperationResultLabel } from '../../../../utilities';
import { InformationBlock, RSActionButton } from '../../../5-elements';
import { formatOperationDate, formatOperationTime } from '../format-operation-date-time';
import { MetadataModal } from '../metadata-modal';
import { OperationLogModal } from '../operation-log-modal';
import { SubOperationHeader } from '../sub-operation-header';
import { SubOperationImageThumbnail } from '../sub-operation-image-thumbnail';

interface SubOperationProps {
  subOperation: Flatten<NonNullable<GetOperationDetailsQuery['deviceOperationByPK']>['deviceOperations']>;
  deviceId: string;
  mainOperationId: string;
  serialNumber: string;
}

export const SubOperation = ({
  subOperation,
  deviceId,
  mainOperationId,
  serialNumber
}: SubOperationProps): JSX.Element => {
  const { t } = useTranslation();
  const [cookies] = useCookies([TIMEZONE_COOKIE_NAME]);
  const { enqueueSnackbar } = useSnackbar();
  const sendMessageToSnackbar = (...args: SnackbarMessageType): void => {
    enqueueSnackbar(constructSnackbarMessage(...args));
  };
  const [openOperationLog, setOpenOperationLog] = useState<boolean>(false);
  const [openMetadata, setOpenMetadata] = useState<boolean>(false);
  const uploadedImageIds = subOperation.deviceOperationImages
    .filter((image) => image.status === ObjectUploadStatus.UploadDone)
    .map((imageItem) => imageItem.id);
  const { loading, data, error } = useQuery(QUERY_GET_OPERATION_IMAGE_URLS, {
    variables: { ids: uploadedImageIds, forDownload: false, imageType: ImageType.Thumbnail },
    skip: isEmpty(uploadedImageIds),
    fetchPolicy: 'no-cache',
    onError: (error) => {
      sendMessageToSnackbar(
        t('operationsPage.operationDetails.images.urlsRequestError'),
        undefined,
        error.message || error.name,
        'error'
      );
    }
  });

  const getImageUrl = (imageId: string, imageUrlData?: GetOperationImageUrlsQuery): string | undefined => {
    if (!imageUrlData) {
      return undefined;
    }
    const imageUrlObject = find(imageUrlData.requestDeviceOperationImageUrls, (item) => item.id === imageId);
    if (imageUrlObject) {
      return imageUrlObject.url;
    }
    return undefined;
  };

  return (
    <div className="sub-operation" data-testid="sub-operation">
      <SubOperationHeader
        title={subOperation.operationName}
        operationResult={{
          success: subOperation.success,
          hasEndedAt: Boolean(subOperation.endAt),
          title: generateOperationResultLabel(subOperation),
          label: generateOperationResultLabel(subOperation)
        }}
        actions={
          <>
            {subOperation.deviceOperationLog && (
              <RSActionButton
                disabled={!subOperation.deviceOperationLog}
                text={t('operationsPage.operationDetails.subOperation.log')}
                data-testid="log-action"
                startIcon={<LogIcon />}
                onClick={() => setOpenOperationLog(true)}
              />
            )}
            {subOperation.metaData && (
              <RSActionButton
                disabled={!subOperation.metaData}
                text={t('operationsPage.operationDetails.subOperation.metadata')}
                data-testid="metadata-action"
                startIcon={<MetadataIcon />}
                onClick={() => setOpenMetadata(true)}
              />
            )}
          </>
        }
      />
      <div className="sub-operation__contents">
        <div className="sub-operation__time" data-testid="sub-operation-time">
          <InformationBlock
            label={t('operationsPage.operationDetails.titles.date')}
            value={formatOperationDate(subOperation.startAt, subOperation.endAt, cookies[TIMEZONE_COOKIE_NAME])}
          />
          <InformationBlock
            label={t('operationsPage.operationDetails.titles.duration')}
            value={calculateDuration(subOperation.startAt, subOperation.endAt)}
          />
          <InformationBlock
            label={t('operationsPage.operationDetails.titles.startAt')}
            value={formatOperationTime(subOperation.startAt, cookies[TIMEZONE_COOKIE_NAME])}
          />
          <InformationBlock
            label={t('operationsPage.operationDetails.titles.endAt')}
            value={formatOperationTime(subOperation.endAt, cookies[TIMEZONE_COOKIE_NAME])}
          />
        </div>
        {!isEmpty(subOperation.deviceOperationImages) && (
          <div className="sub-operation__images">
            <span className="sub-operation__image-title">{t('operationsPage.operationDetails.titles.images')}</span>
            <div className="sub-operation__image-thumbnails">
              {subOperation.deviceOperationImages.map((image) => (
                <SubOperationImageThumbnail
                  key={image.id}
                  image={image}
                  imageUrl={getImageUrl(image.id, data)}
                  loading={loading}
                  hasError={Boolean(error)}
                  deviceId={deviceId}
                  mainOperationId={mainOperationId}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {subOperation.metaData && (
        <MetadataModal
          open={openMetadata}
          metadata={subOperation.metaData}
          metadataTitle={`${serialNumber} ${subOperation.operationName}`}
          setOpenMetadata={setOpenMetadata}
        />
      )}
      {subOperation.deviceOperationLog && (
        <OperationLogModal
          open={openOperationLog}
          logTitle={`${serialNumber} ${subOperation.operationName}`}
          operationId={subOperation.id}
          deviceId={deviceId}
          setOpenOperationLog={setOpenOperationLog}
          log={subOperation.deviceOperationLog}
        />
      )}
    </div>
  );
};
