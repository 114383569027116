import { DateTime } from 'luxon';

import { DATE_FORMAT_TEMPLATE_PERFORMANCE_STATS } from '../../constants';

interface PerformanceDateRanges {
  prevStartDate: string;
  startDate: string;
  endDate: string;
}

export const getPerformanceDateRanges = (): PerformanceDateRanges => {
  const currentDate = DateTime.now();
  const date14daysAgo = currentDate.minus({ days: 14 }).toFormat(DATE_FORMAT_TEMPLATE_PERFORMANCE_STATS);
  const date7daysAgo = currentDate.minus({ days: 7 }).toFormat(DATE_FORMAT_TEMPLATE_PERFORMANCE_STATS);
  const dateToday = currentDate.toFormat(DATE_FORMAT_TEMPLATE_PERFORMANCE_STATS);

  return {
    prevStartDate: date14daysAgo,
    startDate: date7daysAgo,
    endDate: dateToday
  };
};
