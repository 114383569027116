import { GridColDef } from '@mui/x-data-grid';
import { t } from 'i18next';
// import { head, last, split } from 'lodash';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { OperationRow } from './generate-operation-rows';
import { appConfig } from '../../../../configs';
import i18n from '../../../../i18n';
import { generateSequenceNumberString } from '../../../../utilities';
import { DataGridOperationResultCell, DataGridOperationResultCellProps } from '../../../4-features';
import { DataGridHeaderCell, DataGridLink } from '../../../5-elements';

i18n.init();

export const operationsDataGridColumns: GridColDef<OperationRow>[] = [
  {
    field: 'serialNumber',
    hideable: false,
    sortable: false,
    flex: 0.95,
    minWidth: 190,
    headerName: t('operationsPage.tableColumns.serialNumber'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />,
    renderCell: ({ row: { id, deviceId }, value }) => {
      const routerLocation = useLocation();
      const parsedExistingQueryParams = queryString.parse(routerLocation.search, { arrayFormat: 'comma' });
      const queryParamsOperationUrl = { ...parsedExistingQueryParams, operationId: id };
      return (
        <>
          <DataGridLink to={`${appConfig.basePath}/devices/${deviceId}`}>{value.serialNumber}</DataGridLink>
          {' - '}
          <DataGridLink to={`${appConfig.basePath}/operations?${queryString.stringify(queryParamsOperationUrl)}`}>
            {generateSequenceNumberString(value.sequence)}
          </DataGridLink>
        </>
      );
    }
  },
  {
    field: 'result',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 200,
    headerName: t('operationsPage.tableColumns.result'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />,
    renderCell: ({ value }) => {
      const operationResultValue = value as DataGridOperationResultCellProps;
      return <DataGridOperationResultCell {...operationResultValue} />;
    }
  },
  {
    field: 'dateTime',
    hideable: false,
    sortable: false,
    flex: 0.9,
    minWidth: 180,
    headerName: t('operationsPage.tableColumns.dateTime'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />
  },
  {
    field: 'duration',
    hideable: false,
    sortable: false,
    flex: 0.45,
    minWidth: 90,
    headerName: t('operationsPage.tableColumns.duration'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} field={colDef.field} />
  },
  {
    field: 'operationalLifeCycle',
    hideable: false,
    sortable: false,
    flex: 0.95,
    minWidth: 190,
    headerName: t('operationsPage.tableColumns.operationalLifeCycle'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />
  },
  {
    field: 'customer',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 200,
    headerName: t('operationsPage.tableColumns.customer'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />,
    renderCell: ({ row: { customerId }, value }) => {
      return <DataGridLink to={`${appConfig.basePath}/customers/${customerId}`}>{value}</DataGridLink>;
    }
  },
  {
    field: 'site',
    hideable: false,
    sortable: false,
    flex: 1,
    minWidth: 200,
    headerName: t('operationsPage.tableColumns.site'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />
  },
  {
    field: 'program',
    hideable: false,
    sortable: false,
    flex: 0.55,
    minWidth: 110,
    headerName: t('operationsPage.tableColumns.program'),
    renderHeader: ({ colDef }) => <DataGridHeaderCell title={colDef.headerName!} sortable field={colDef.field} />
  }
];
