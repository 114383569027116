import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { head, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { CustomerSitesList } from './customer-sites-list';
import { QUERY_GET_CUSTOMER_DETAILS } from '../../../services/queries';
import { generatePerformanceMetricsFilters } from '../../../utilities';
import { DetailsMainSectionWrapper } from '../../2-templates';
import { PerformanceMetrics } from '../../4-features';
import { CustomerDetailsSidePanel, generateCustomerDetailsPerformanceMetricsFilter } from '../customer-details-page';
import { ErrorPage } from '../error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page';

export const CustomerDetailsPage = (): JSX.Element => {
  const { customerId } = useParams();
  const { loading, data, error } = useQuery(QUERY_GET_CUSTOMER_DETAILS, {
    variables: { companyId: customerId, ...generatePerformanceMetricsFilters({}) }
  });

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');
    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  const isLoading = loading || !data;

  if (isLoading) {
    return <LoadingPage />;
  }

  const customers = data?.customers;

  if (isEmpty(customers)) {
    return <PageNotFoundErrorPage />;
  }

  const customer = head(customers)!;

  return (
    <DetailsMainSectionWrapper
      panel={
        <CustomerDetailsSidePanel
          title={customer.company.name}
          counts={{
            devices: customer.devicesAggregate.aggregate?.count || 0,
            sites: customer.sitesAggregate.aggregate?.count || 0,
            programs: customer.programs.length || 0
          }}
          createdAt={customer.company.createdAt}
          updatedAt={customer.company.updatedAt}
          programs={customer.programs}
        />
      }
    >
      <section className="customer-details-page" data-testid="customer-details-page">
        <div className="customer-details-page__overview">
          <div className="customer-details-page__title-bar">
            <h2>{t('customerDetailsPage.metrics.title')}</h2>
            <p className="customer-details-page__title-bar-date-range">{t('customerDetailsPage.metrics.dateRange')}</p>
          </div>
          <PerformanceMetrics
            layout="full-width"
            filters={generateCustomerDetailsPerformanceMetricsFilter(customerId!)}
          />
        </div>
        <div className="customer-details-page__sites">
          <div className="customer-details-page__title-bar">
            <h2>{t('customerDetailsPage.sites.title')}</h2>
          </div>
          <CustomerSitesList sites={customer.sites} />
        </div>
      </section>
    </DetailsMainSectionWrapper>
  );
};
