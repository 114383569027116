import { GridColDef } from '@mui/x-data-grid';
import { t } from 'i18next';

import { CustomerProgramRow } from './generate-customer-program-rows';
import i18n from '../../../../../i18n';
import { generatePerformanceMetricsFilters } from '../../../../../utilities';
import { DataGridHeaderCell, DataGridPerformanceMetricsCellWithQuery } from '../../../../5-elements';

i18n.init();

export const customerProgramsTableColumns: GridColDef<CustomerProgramRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: false,
    width: 120,
    headerName: t('customerDetailsPage.sidePanel.programsTable.name'),
    headerClassName: 'customer-programs-table__program-header',
    cellClassName: 'customer-programs-table__program-cell',
    renderHeader: ({ colDef }) => (
      <DataGridHeaderCell title={colDef.headerName!} sortable={false} field={colDef.field} />
    )
  },
  {
    field: 'performance',
    hideable: false,
    sortable: false,
    width: 160,
    headerClassName: 'customer-programs-table__performance-header',
    cellClassName: 'customer-programs-table__performance-cell',
    headerName: t('customerDetailsPage.sidePanel.programsTable.performance'),
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable={false} field={colDef.field} />;
    },
    renderCell: ({ value }) => (
      <DataGridPerformanceMetricsCellWithQuery
        variables={generatePerformanceMetricsFilters({ device: { programId: { _eq: value } } })}
      />
    )
  },
  {
    field: 'rocs',
    hideable: false,
    sortable: false,
    width: 70,
    headerName: t('customerDetailsPage.sidePanel.programsTable.devices'),
    align: 'right',
    headerAlign: 'right',
    headerClassName: 'customer-programs-table__rocs-header',
    cellClassName: 'customer-programs-table__rocs-cell',
    renderHeader: ({ colDef }) => {
      return <DataGridHeaderCell title={colDef.headerName!} sortable={false} field={colDef.field} />;
    }
  }
];
