import { gql } from '../../../__generated__/gql';

export const QUERY_GET_CUSTOMER_DETAILS = gql(`
  query getCustomerDetails($companyId: uuid) {
    customers(where: { companyId: { _eq: $companyId } }) {
      companyId
      company {
        id
        name
        createdAt
        updatedAt
      }
      programs(orderBy: [{ name: ASC }]) {
        id
        name
        devicesAggregate {
          aggregate {
            count
          }
        }
      }
      devicesAggregate(where: { active: { _eq: true } }) {
        aggregate {
          count
        }
      }
      sitesAggregate {
        aggregate {
          count
        }
      }
      sites {
        id
        name
        location {
          id
          formattedAddress
          latitude
          longitude
        }
        devices(distinctOn: programId) {
          program {
            id
            name
          }
        }
        devicesAggregate(where: { active: { _eq: true } }) {
          aggregate {
            count
          }
        }
      }
    }
  }
`);
