import { useQuery } from '@apollo/client';
import { GridPaginationModel, GridRowId } from '@mui/x-data-grid';
import { get, isEqual, pick, toNumber, toString } from 'lodash';
import queryString from 'query-string';
import { JSX, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { deviceDataGridColumns, generateDeviceRows } from './data-grid-configurations';
import { DevicesFilterPanel } from './devices-filter-panel';
import { DevicesOverviewSearchParameters, devicesOverviewStatesSchema } from './devices-overview-states-schema';
import { deviceFilterFields, generateFilterQuery, generateSortQuery } from './generate-queries';
import { PerformanceMetricsDeviceAggregate } from './performance-metrics-device-aggregate';
import { DeviceBoolExp, DeviceOrderBy } from '../../../__generated__/graphql';
import { appConfig } from '../../../configs';
import { DEFAULT_GET_DEVICES_SORT_BY, DEFAULT_PAGE_SIZE } from '../../../constants';
import {
  QUERY_GET_CONNECTOR_TYPES,
  QUERY_GET_CUSTOMER_NAMES,
  QUERY_GET_DEVICES,
  QUERY_GET_PROGRAM_NAMES,
  QUERY_GET_ROC_OS_VERSIONS,
  QUERY_GET_SERIAL_NUMBERS,
  QUERY_GET_SITE_NAMES
} from '../../../services/queries';
import { DeviceDeactivated } from '../../../types';
import {
  calculatePaginationEndRow,
  calculatePaginationPageCount,
  calculatePaginationStartRow,
  filterValidUrlFields
} from '../../../utilities';
import { OverviewMainSectionWrapper } from '../../2-templates';
import { PaginationBar, RSDataGrid } from '../../4-features';
import { ScreenTitle } from '../../5-elements';
import { ErrorPage } from '../error-page';

export const DevicesPage = (): JSX.Element => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const { basePath } = appConfig;
  const [queryParamsLoaded, setQueryParamsLoaded] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [sortingOptions, setSortingOptions] = useState<DeviceOrderBy[]>(DEFAULT_GET_DEVICES_SORT_BY);
  const [filterOptions, setFilterOptions] = useState<DeviceBoolExp>({});

  const { loading, data, error } = useQuery(QUERY_GET_DEVICES, {
    variables: {
      offset: (paginationModel.page - 1) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
      orderBy: sortingOptions,
      filters: filterOptions
    },
    fetchPolicy: 'cache-and-network',
    skip: !queryParamsLoaded
  });
  const {
    loading: loadingSerialNumber,
    data: dataSerialNumber,
    error: errorSerialNumber
  } = useQuery(QUERY_GET_SERIAL_NUMBERS, { fetchPolicy: 'network-only' });
  const {
    loading: loadingConnectorTypes,
    data: dataConnectorTypes,
    error: errorConnectorTypes
  } = useQuery(QUERY_GET_CONNECTOR_TYPES, { fetchPolicy: 'network-only' });
  const {
    loading: loadingCustomerNames,
    data: dataCustomerNames,
    error: errorCustomerNames
  } = useQuery(QUERY_GET_CUSTOMER_NAMES, {
    fetchPolicy: 'network-only',
    // Only get customers with at least one device
    variables: { filters: { sites: { devices: {} } } }
  });
  const {
    loading: loadingSiteNames,
    data: dataSiteNames,
    error: errorSiteNames
    // Only get sites with at least one device
  } = useQuery(QUERY_GET_SITE_NAMES, { fetchPolicy: 'network-only', variables: { filters: { devices: {} } } });
  const {
    loading: loadingRocOSVersions,
    data: dataRocOSVersions,
    error: errorRocOSVersions
  } = useQuery(QUERY_GET_ROC_OS_VERSIONS, { fetchPolicy: 'network-only' });
  const {
    loading: loadingProgramNames,
    data: dataProgramNames,
    error: errorProgramNames
  } = useQuery(QUERY_GET_PROGRAM_NAMES, { fetchPolicy: 'network-only', variables: { filters: { devices: {} } } });

  const searchParameters = queryString.parse(searchParams.toString(), {
    arrayFormat: 'comma'
  });

  const validUrlFields = filterValidUrlFields<DevicesOverviewSearchParameters>(
    searchParameters,
    devicesOverviewStatesSchema
  );

  const handleRowClick = (rowId: GridRowId): void => {
    navigate(`${basePath}/devices/${rowId}`);
  };

  useEffect(() => {
    // Set page
    const pageNumber = toNumber(get(validUrlFields, 'page')) || 1;
    if (paginationModel.page !== pageNumber) {
      setPaginationModel((prevModel) => ({ ...prevModel, page: pageNumber }));
    }

    // Set sorting
    const sortParameter = get(validUrlFields, 'sort');
    const sortQuery = generateSortQuery(sortParameter);
    if (!isEqual(sortQuery, sortingOptions)) {
      setSortingOptions(sortQuery);
    }

    // Set filtering
    const filterParameters = pick(validUrlFields, deviceFilterFields);
    if (!filterParameters.deactivated && !queryParamsLoaded) {
      searchParams.set('deactivated', DeviceDeactivated.Activated);
      setSearchParams(searchParams);
    }
    const filterQuery = generateFilterQuery(filterParameters);
    if (!isEqual(filterQuery, filterOptions)) {
      setFilterOptions(filterQuery);
    }

    // The `queryParamsLoaded` will only be set once, the default `active` value needs to be set
    if (!queryParamsLoaded && validUrlFields.deactivated) {
      setQueryParamsLoaded(true);
    }

    // `searchParams` is a URLSearchParams whilst `routerLocation.search` is a string - compare string-type dependency
    // aligns with "useEffect dependencies compare the value change"
  }, [routerLocation.search]);

  const groupedError =
    error ||
    errorSerialNumber ||
    errorConnectorTypes ||
    errorCustomerNames ||
    errorSiteNames ||
    errorRocOSVersions ||
    errorProgramNames;

  if (groupedError) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  const isLoading =
    loading ||
    loadingSerialNumber ||
    loadingConnectorTypes ||
    loadingCustomerNames ||
    loadingSiteNames ||
    loadingRocOSVersions ||
    loadingProgramNames;

  return (
    <OverviewMainSectionWrapper
      filter={
        <DevicesFilterPanel
          serialNumber={dataSerialNumber}
          rocOSVersions={dataRocOSVersions}
          connectorTypes={dataConnectorTypes}
          customerNames={dataCustomerNames}
          siteNames={dataSiteNames}
          programNames={dataProgramNames}
          defaultValues={{
            serialNumber: validUrlFields.serialNumber,
            operationalLifeCycle: validUrlFields.operationalLifeCycle,
            rocOS: validUrlFields.rocOS,
            connectorType: validUrlFields.connectorType,
            customer: validUrlFields.customer,
            site: validUrlFields.site,
            program: validUrlFields.program
          }}
        />
      }
    >
      <section className="devices-page" data-testid="devices-page">
        <div className="devices-page__overview-top-bar">
          <ScreenTitle title={t('devicesPage.title')} />
          <PerformanceMetricsDeviceAggregate
            queryHookOptions={{
              skip: !queryParamsLoaded,
              variables: { filters: filterOptions },
              fetchPolicy: 'cache-and-network'
            }}
          />
        </div>
        <div className="devices-page__data-grid-container">
          <RSDataGrid
            columns={deviceDataGridColumns}
            rows={generateDeviceRows(data)}
            loading={isLoading}
            onRowClick={(row) => handleRowClick(row.id)}
            data-testid="devices-page-data-grid"
          />
        </div>
        <div className="devices-page__pagination">
          <PaginationBar
            isLoading={loading}
            startRow={calculatePaginationStartRow(paginationModel.page, DEFAULT_PAGE_SIZE)}
            endRow={calculatePaginationEndRow(
              paginationModel.page,
              DEFAULT_PAGE_SIZE,
              data?.devicesAggregate.aggregate?.count
            )}
            rowCount={data?.devicesAggregate.aggregate?.count}
            pageCount={calculatePaginationPageCount(data?.devicesAggregate.aggregate?.count)}
            onChange={(_event, page) => {
              const originalSearchParamsObject = queryString.parse(searchParams.toString(), {
                arrayFormat: 'comma'
              });
              setSearchParams(new URLSearchParams({ ...originalSearchParamsObject, page: toString(page) }));
            }}
            page={paginationModel.page}
          />
        </div>
      </section>
    </OverviewMainSectionWrapper>
  );
};
