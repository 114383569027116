import { useQuery } from '@apollo/client';
import { InputLabel, MenuItem, MenuItemProps, Select } from '@mui/material';
import { join, split, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { JSX, useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { GetSiteTimezonesQuery } from '../../../__generated__/graphql';
import AngleBracketDownIcon from '../../../assets/icons/angle-brackets-down.svg?react';
import WorldClockIcon from '../../../assets/icons/world-clock.svg?react';
import { TIMEZONE_COOKIE_NAME } from '../../../constants';
import { QUERY_GET_SITE_TIMEZONES } from '../../../services/queries';
import { Flatten } from '../../../types';
import { browserTimezone, getBrowserLocale, validateTimezone } from '../../../utilities';

interface TimezoneSelectMenuItemProps extends Omit<MenuItemProps, 'children'> {
  displayName: string;
  abbreviation: string;
  time: string;
}

export const TimezoneSelect = (): JSX.Element => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(QUERY_GET_SITE_TIMEZONES);
  const [cookies, setCookie] = useCookies([TIMEZONE_COOKIE_NAME]);
  // When opening the select menu, we want the time to be updated
  // https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const getTimezoneNamesFromSites = (sites: GetSiteTimezonesQuery['sites']) => {
    return sites.map((site) => ({
      timezoneName: site.timezone.name,
      timezoneAbbreviation: site.timezone.timezoneInfo?.abbrev
    }));
  };

  type TimezoneNameType = Flatten<ReturnType<typeof getTimezoneNamesFromSites>>;

  const generateTimezoneSelectOptionNew = (timezoneName: TimezoneNameType): TimezoneSelectMenuItemProps => {
    return {
      displayName: join(split(timezoneName.timezoneName, '/'), ' / '),
      value: timezoneName.timezoneName,
      time: DateTime.local({ zone: timezoneName.timezoneName })
        .setLocale(getBrowserLocale() || 'en-GB')
        .toFormat('HH:mm'),
      abbreviation:
        timezoneName.timezoneAbbreviation ||
        DateTime.local({ zone: timezoneName.timezoneName })
          .setLocale(getBrowserLocale() || 'en-GB')
          .toFormat('ZZZZ')
    };
  };

  useEffect(() => {
    const cookieTimezone = cookies[TIMEZONE_COOKIE_NAME];
    if (!cookieTimezone || !validateTimezone(cookieTimezone)) {
      setCookie(TIMEZONE_COOKIE_NAME, browserTimezone);
    }
  }, [cookies[TIMEZONE_COOKIE_NAME]]);

  if (loading) {
    return <></>;
  }

  const timezones: TimezoneNameType[] = uniqBy(
    [
      {
        timezoneName: browserTimezone,
        timezoneAbbreviation: DateTime.local({ zone: browserTimezone })
          .setLocale(getBrowserLocale() || 'en-GB')
          .toFormat('ZZZZ')
      },
      { timezoneName: 'UTC', timezoneAbbreviation: 'UTC' },
      ...getTimezoneNamesFromSites(data?.sites || [])
    ],
    'timezoneName'
  );
  const timezoneOptions = timezones.map((timezone) => generateTimezoneSelectOptionNew(timezone));

  return (
    <div className="timezone-select" data-testid="timezone-select">
      <InputLabel shrink={true} className="timezone-select__input-label" data-testid="timezone-select-input-label">
        {t('timezoneSelect.timezone')}
      </InputLabel>
      <div className="timezone-select__menu">
        <div className="timezone-select__menu-icon">
          <WorldClockIcon />
        </div>
        <Select
          className="timezone-select__select"
          data-testid="timezone-select-menu"
          IconComponent={AngleBracketDownIcon}
          disabled={loading}
          onOpen={forceUpdate}
          value={(validateTimezone(cookies[TIMEZONE_COOKIE_NAME]) && cookies[TIMEZONE_COOKIE_NAME]) || browserTimezone}
          onChange={(event) => setCookie(TIMEZONE_COOKIE_NAME, event.target.value)}
          MenuProps={{
            sx: { top: '2.1rem', left: '-0.9rem' },
            slotProps: {
              root: {
                slotProps: {
                  backdrop: { className: 'timezone-select__backdrop' },
                  root: { className: 'timezone-select__popover' }
                }
              }
            }
          }}
        >
          {timezoneOptions.map(({ displayName, abbreviation, time, ...timezoneValueProps }) => (
            <MenuItem
              {...timezoneValueProps}
              key={timezoneValueProps.value as string}
              className="timezone-select__menu-item"
              data-testid={`timezone-select-item-${timezoneValueProps.value}`}
            >
              <div key={`${timezoneValueProps.value}-option`} className="timezone-select__option">
                <div className="timezone-select__tz-identifier">
                  <span className="timezone-select__display-name">{displayName}</span>
                  <span className="timezone-select__abbreviation">{abbreviation}</span>
                </div>
                <div>
                  <span className="timezone-select__time">{time}</span>
                </div>
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};
