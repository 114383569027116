import { Link, LinkProps } from 'react-router-dom';

type DataGridLinkProps = LinkProps;

export const DataGridLink = ({ children, ...props }: DataGridLinkProps) => {
  return (
    <Link
      {...props}
      className="data-grid-link"
      data-testid="data-grid-link"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {children}
    </Link>
  );
};
