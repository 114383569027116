import { GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { JSX, SetStateAction } from 'react';
import { useCookies } from 'react-cookie';

import { deviceLatestOperationColumns } from './device-latest-operation-columns';
import { DeviceLatestOperationRow, generateDeviceLatestOperationRows } from './generate-device-latest-operation-rows';
import { GetDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { TIMEZONE_COOKIE_NAME } from '../../../../constants';
import { OperationDetailsBasicInformation } from '../../../3-sections';
import { RSDataGrid } from '../../../4-features';

interface DeviceLatestOperationsProps {
  operations: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceOperations'];
  serialNumber?: string | null;
  setSelectedOperation: (value: SetStateAction<OperationDetailsBasicInformation | undefined>) => void;
  setOperationDetailsOpen: (value: SetStateAction<boolean>) => void;
  customerName: string;
  siteName: string;
  programNumber?: string;
  customerId: string;
}

export const DeviceLatestOperations = ({
  operations,
  serialNumber,
  setSelectedOperation,
  setOperationDetailsOpen,
  customerName,
  siteName,
  programNumber,
  customerId
}: DeviceLatestOperationsProps): JSX.Element => {
  const [cookies] = useCookies([TIMEZONE_COOKIE_NAME]);

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<DeviceLatestOperationRow>) => {
    setSelectedOperation({
      id: params.row.id,
      serialNumber: params.row.serialNumber, // We must use the row serial number (with sequence number)
      customer: customerName,
      site: siteName,
      program: programNumber,
      customerId
    });
    setOperationDetailsOpen(true);
  };

  return (
    <RSDataGrid
      columns={deviceLatestOperationColumns}
      rows={generateDeviceLatestOperationRows(operations, serialNumber, cookies[TIMEZONE_COOKIE_NAME])}
      data-testid="device-latest-operations"
      onRowClick={handleRowClick}
      autoHeight={true}
    />
  );
};
